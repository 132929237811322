import React from "react";
import Navigation from "./Navigation";


const LatestNewsMain = () => {
    return(
      <div>
        <Navigation />
        <div>Latest news main</div>
      </div>
    )
    }

    export default LatestNewsMain;